import React from "react";
import Helmet from "react-helmet";
import TicketShop from "components/ticket-shop";
import { MotionImage } from "components/MotionImage";
import { HeroCarousel } from "components/HeroCarousel";

import { Page } from "components/Page";
import { Photo } from "components/Photo";

const IndexPage = (p) => {
  return (
    <Page overlayHeader>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Rockstar Promotions"}
        meta={[
          {
            name: `description`,
            content: "Rockstar Promotions",
          },
          {
            property: `og:url`,
            content: `https://rockstarpromotions.co.uk`,
          },
          {
            property: `og:title`,
            content: "Rockstar Promotions",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: `https://rockstarpromotions.co.uk/darts.jpg`,
          },
        ]}
      />
      <HeroCarousel
        images={[
          "darts.jpg",
          "hero-02.jpg",
          "hero-03.jpg",
          "hero-04.jpg",
          "raceday.jpg",
        ]}
      >
        <div className="absolute bottom-1 z-10 sm:bottom-4">
          <div className="container relative flex gap-4">
            <a href="https://apps.apple.com/gb/app/rockstar-promotions/id1580083061">
              <img src="/appstore.svg" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=co.fixr_wl.rockstar.android">
              <img style={{ height: 40 }} src="/playstore.svg" />
            </a>
          </div>
        </div>
      </HeroCarousel>
      <section className="mt-16">
        <div className="container mx-auto">
          <div className="mt-8 flex flex-col gap-2 md:mt-16 md:flex-row md:justify-between">
            <div className="mb-8 max-w-xl" style={{ minWidth: 320 }}>
              <h1 className="mb-8 text-2xl md:mt-8 md:text-3xl">
                ROCKSTAR PROMOTIONS
              </h1>
              <p>
                Established in 2007, the award winning Rockstar Promotions are
                one of the leading entertainment event companies in the UK.
              </p>
              <p>
                We run several club nights including LetsDisko, Supersonic and
                Electric 80’s, as well as Festivals such as Fresh Festival and
                Halloween Festival! We also run other large scale events,
                including Highcross Student Sessions shopping event, a Student
                Race Day at Leicester Racecourse, and Athena Sit Down Social
                events, as well as offering our event services to a number of
                clients and venues.
              </p>
            </div>
            <div className="relative flex flex-1 justify-items-center md:justify-end">
              <Photo
                className="relative w-72 translate-x-4 transform md:translate-x-20"
                bgColor="var(--yellow)"
                src="home-1.jpg"
                offsetX={-16}
                offsetY={16}
              >
                <MotionImage
                  className="absolute -bottom-12 -left-6 w-24"
                  src={"/icon-note-01.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.75,
                      delay: 1,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                />
              </Photo>
              <Photo
                className="relative w-72 translate-y-20 transform md:translate-y-32"
                bgColor="var(--blue)"
                src="home-2.jpg"
              >
                <MotionImage
                  className="absolute -top-8 left-0 w-16 md:-top-28 md:left-10"
                  src={"/icon-lightning.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.95,
                      delay: 0.8,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                />
                <MotionImage
                  className="absolute -bottom-8 right-8 w-16"
                  src={"/icon-note-02.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.85,
                      delay: 0.65,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                />
              </Photo>
            </div>
          </div>
          <div className="mt-32 md:mt-32">
            <h2 className="text-center text-2xl">EVENTS</h2>
            <TicketShop
              data-next-event-heading=""
              scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="c9b328b7-d5d4-49ca-bf12-ed2de551af5f" data-fixr-theme="dark"></script>`}
            />
          </div>
        </div>
      </section>
    </Page>
  );
};

export default IndexPage;
